<template>
  <div class="email-activity-list">
    <!-- Statistics Card -->
    <CCard class="mb-4">
      <CCardBody>
        <div class="statistics-cards">
          <div v-for="stat in statistics" :key="stat.label" class="stat-card">
            <div class="d-flex align-items-center">
              <div class="stat-icon" :class="`bg-${stat.color}-light text-${stat.color}`">
                <CIcon :name="stat.icon" />
              </div>
              <div class="stat-content">
                <h4 class="stat-value">{{ stat.value }}</h4>
                <small class="stat-label text-muted">{{ stat.label }}</small>
              </div>
            </div>
          </div>
        </div>
      </CCardBody>
    </CCard>

    <!-- Filter Card -->
    <CCard class="mb-4">
      <CCardBody>
        <div class="filter-container">
          <!-- Suche -->
          <div class="filter-item">
            <CInput
              v-model="filters.search"
              placeholder="Nach Betreff oder Inhalt suchen..."
            >
              <template #prepend-content>
                <CIcon name="cil-search" />
              </template>
            </CInput>
          </div>

          <!-- Typ Filter -->
          <div class="filter-item">
            <CSelect
              v-model="filters.type"
              :plain="true"
              :options="[
                { value: 'all', label: 'Jeder Status' },
                { value: 'sent', label: 'Versendet' },
                { value: 'opened', label: 'Geöffnet' },
                { value: 'clicked', label: 'Angeklickt' },
                { value: 'rating', label: 'Bewertung' }
              ]"
            />
          </div>

          <!-- Empfänger Filter -->
          <div class="filter-item">
            <CSelect
              v-model="filters.recipient"
              :plain="true"
              :options="[
                { value: 'all', label: 'Alle Empfänger' },
                { value: 'customer', label: 'Kunde' },
                { value: 'admin', label: 'Administrator' }
              ]"
            />
          </div>

          <!-- Clear Filters Button -->
          <div class="filter-item" v-if="hasActiveFilters">
            <CButton color="light" @click="clearFilters">
              Filter zurücksetzen
              <CIcon name="cil-x" />
            </CButton>
          </div>
        </div>
      </CCardBody>
    </CCard>

    <!-- Loading State -->
    <div v-if="loading" class="loading-state mb-4">
      <CSpinner color="primary" size="sm" />
      <div class="loading-text">
        <p class="mb-0">Lade E-Mail-Aktivitäten...</p>
        <small class="text-muted">Dies kann einen Moment dauern</small>
      </div>
    </div>

    <!-- Email Activities -->
    <CCard v-if="Object.keys(filteredGroupedByDate).length > 0">
      <CCardBody>
        <div class="activity-timeline">
          <div v-for="(dateGroup, date) in filteredGroupedByDate" :key="date" class="timeline-group">
            <div class="date-separator">
              {{ formatDate(date) }}
              <small class="text-muted">
                {{ date === 'Heute' ? '(Heute)' : date === 'Gestern' ? '(Gestern)' : '' }}
              </small>
            </div>

            <div v-for="group in dateGroup" :key="group.id" class="email-group mb-3">
              <div
                class="email-group-header p-3"
                :class="[
                  getActivityClass(group.latestActivity),
                  {'is-expanded': group.showDetails}
                ]"
              >
                <div class="d-flex justify-content-between align-items-start">
                  <div class="d-flex align-items-start">
                    <div class="activity-icon mr-3">
                      <CIcon :name="getActivityIcon(group.latestActivity)" />
                    </div>
                    <div class="activity-content">
                      <h6 class="mb-1">{{ getActivityDetails(group.latestActivity) }}</h6>
                      <div class="status-badges">
                        <CBadge
                          v-for="status in group.statusSummary"
                          :key="status.type"
                          :color="getStatusColor(status.type)"
                          class="status-badge mr-2"
                        >
                          <CIcon
                            :name="getStatusIcon(status.type)"
                            class="status-icon mr-1"
                          />
                          {{ getActivityBadgeText({ ...group.latestActivity, eventType: status.type }) }}:
                          {{ formatDateTime(status.timestamp) }}
                        </CBadge>
                      </div>
                    </div>
                  </div>
                  <div class="email-controls">
                    <CButton
                      color="light"
                      variant="ghost"
                      @click="group.showDetails = !group.showDetails"
                    >
                      <CIcon :name="group.showDetails ? 'cil-chevron-top' : 'cil-chevron-bottom'" />
                    </CButton>
                  </div>
                </div>
              </div>

              <!-- Detailed Timeline -->
              <CCollapse :show="group.showDetails">
                <div class="email-details px-4 py-2">
                  <div class="timeline-details">
                    <div
                      v-for="activity in group.activities"
                      :key="activity.id"
                      class="timeline-event py-2"
                    >
                      <div class="timeline-dot" :class="getEventColor(activity.type)"></div>
                      <div class="timeline-content">
                        <div class="d-flex justify-content-between">
                          <span class="event-type">{{ getActivityBadgeText(activity) }}</span>
                          <span class="event-time">{{ formatTime(activity.timestamp) }}</span>
                        </div>
                        <div v-if="activity.details" class="event-details text-muted mt-1">
                          {{ activity.details }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </CCollapse>
            </div>
          </div>
        </div>
      </CCardBody>
    </CCard>

    <!-- Empty State -->
    <CCard v-else>
      <CCardBody class="text-center py-5">
        <CIcon name="cil-inbox" size="xl" class="text-muted mb-3" />
        <h5>Keine Aktivitäten gefunden</h5>
        <p class="text-muted">
          {{ hasActiveFilters ? 'Versuchen Sie andere Filtereinstellungen.' : 'Es sind noch keine E-Mail-Aktivitäten vorhanden.' }}
        </p>
        <CButton
          v-if="hasActiveFilters"
          color="primary"
          variant="outline"
          @click="clearFilters"
        >
          Filter zurücksetzen
        </CButton>
      </CCardBody>
    </CCard>
  </div>
</template>

<script>
import moment from 'moment';
import 'moment/locale/de';

export default {
  name: 'EmailActivityList',

  props: {
    emailInteractions: {
      type: Array,
      default: () => []
    },
    activities: {
      type: Array,
      default: () => []
    },
    loading: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      filters: {
        search: '',
        type: 'all',
        recipient: 'all'
      },
      currentPage: 0,
      loading: false,
      localEmailInteractions: [],  // Initialisiert als leeres Array
      localActivities: []          // Initialisiert als leeres Array
    };
  },


  watch: {
    emailInteractions: {
      immediate: true,
      deep: true,
      handler(newVal) {
        console.log('Email interactions updated:', newVal);
        this.localEmailInteractions = Array.isArray(newVal) ? [...newVal] : [];
        this.processActivities();
      }
    },
    activities: {
      immediate: true,
      deep: true,
      handler(newVal) {
        console.log('Activities updated:', newVal);
        this.localActivities = Array.isArray(newVal) ? [...newVal] : [];
        this.processActivities();
      }
    }
  },

  computed: {
    computedData() {
      return {
        emailInteractions: this.emailInteractions,
        activities: this.activities
      };
    },

    statistics() {
      const stats = {
        customerEmails: new Set(),  // Eindeutige E-Mails an Kunden
        adminNotifications: new Set(), // Eindeutige Admin-Benachrichtigungen
        opened: new Set(),         // Eindeutige Öffnungen
        clicked: new Set()         // Eindeutige Klicks
      };

      Object.values(this.filteredGroupedByDate).flat().forEach(group => {
        group.statusSummary.forEach(status => {
          const type = status.type.toUpperCase();
          const mailgunId = status.mailgunId;

          if (this.isAdminNotification(status)) {
            stats.adminNotifications.add(mailgunId);
          } else {
            if (type.includes('SENT') || type.includes('SEND')) {
              stats.customerEmails.add(mailgunId);
            }
            if (type.includes('OPENED')) {
              stats.opened.add(mailgunId);
            }
            if (type.includes('CLICKED')) {
              stats.clicked.add(mailgunId);
            }
          }
        });
      });

      return [
        {
          label: 'E-Mails an Kunden',
          value: stats.customerEmails.size,
          icon: 'cil-envelope-closed',
          color: 'primary'
        },
        {
          label: 'Geöffnete E-Mails',
          value: stats.opened.size,
          icon: 'cil-envelope-open',
          color: 'success'
        },
        {
          label: 'Link-Klicks',
          value: stats.clicked.size,
          icon: 'cil-cursor',
          color: 'warning'
        },
        {
          label: 'Admin-Benachrichtigungen',
          value: stats.adminNotifications.size,
          icon: 'cil-bell',
          color: 'info'
        }
      ];
    },

    hasActiveFilters() {
      return this.filters.search !== '' ||
        this.filters.type !== 'all' ||
        this.filters.recipient !== 'all';
    },

    filteredGroupedByDate() {
      const grouped = {};
      // Stelle sicher, dass wir mit lokalem State arbeiten
      const emailGroups = this.groupActivitiesByEmail();

      if (!emailGroups || emailGroups.length === 0) {
        return {};
      }

      // Filtern der Gruppen basierend auf den aktiven Filtern
      const filteredGroups = emailGroups.filter(group => {
        // Ignoriere Gruppen ohne gültige Details
        if (!group.activities.some(activity => this.getActivityDetails(activity))) {
          return false;
        }

        // Textsuche
        if (this.filters.search) {
          const searchTerm = this.filters.search.toLowerCase();
          const subject = (group.subject || '').toLowerCase();
          const details = group.activities.some(activity => {
            const activityDetails = this.getActivityDetails(activity);
            return activityDetails && activityDetails.toLowerCase().includes(searchTerm);
          });

          if (!subject.includes(searchTerm) && !details) {
            return false;
          }
        }

        // Typ-Filter
        if (this.filters.type !== 'all') {
          const hasMatchingType = group.activities.some(activity => {
            const type = this.getActivityType(activity).toLowerCase();
            switch(this.filters.type) {
              case 'sent':
                return ['sent', 'mail_send', 'rating_send', 'funnel_send'].includes(type);
              case 'opened':
                return ['opened', 'mail_opened', 'rating_opened', 'funnel_opened'].includes(type);
              case 'clicked':
                return ['clicked', 'link_clicked'].includes(type);
              case 'rating':
                return ['rating_send', 'rating_opened'].includes(type);
              default:
                return false;
            }
          });

          if (!hasMatchingType) {
            return false;
          }
        }

        return true;
      });

      // Gruppierung nach Datum der gefilterten Einträge
      filteredGroups.forEach(group => {
        const date = this.formatDate(group.latestActivity.timestamp);
        if (!grouped[date]) {
          grouped[date] = [];
        }
        grouped[date].push(group);
      });

      return grouped;
    },

    groupedByDate() {
      console.log('Computing groupedByDate with:', {
        emailInteractions: this.emailInteractions,
        activities: this.activities
      });

      const grouped = {};
      const emailGroups = this.groupActivitiesByEmail();

      if (!emailGroups || emailGroups.length === 0) {
        return {};
      }

      emailGroups.forEach(group => {
        if (!group.latestActivity) return;

        const date = this.formatDate(group.latestActivity.timestamp);
        if (!grouped[date]) {
          grouped[date] = [];
        }
        grouped[date].push(group);
      });

      return grouped;
    }
  },

  methods: {

    formatDateTime(timestamp) {
      const date = moment(timestamp);
      const today = moment().startOf('day');
      const yesterday = moment().subtract(1, 'days').startOf('day');

      if (date.isSame(today, 'day')) {
        return `Heute, ${date.format('HH:mm')} Uhr`;
      } else if (date.isSame(yesterday, 'day')) {
        return `Gestern, ${date.format('HH:mm')} Uhr`;
      } else {
        return date.format('DD.MM.YY, HH:mm') + ' Uhr';
      }
    },

    getStatusIcon(type) {
      const icons = {
        'SENT': 'cil-envelope-closed',
        'DELIVERED': 'cil-envelope-closed',
        'OPENED': 'cil-envelope-open',
        'CLICKED': 'cil-cursor',
        'FAILED': 'cil-x-circle',
        'MAIL_SEND': 'cil-envelope-closed',
        'MAIL_OPENED': 'cil-envelope-open',
        'LINK_CLICKED': 'cil-cursor',
        'RATING_SEND': 'cil-star',
        'RATING_OPENED': 'cil-star',
        'OPT_IN_CONFIRMED': 'cil-check-circle',
        'FUNNEL_SEND': 'cil-envelope-closed',
        'FUNNEL_OPENED': 'cil-envelope-open'
      };
      return icons[type] || 'cil-envelope-letter';
    },

    toggleGroupDetails(group) {
      group.showDetails = !group.showDetails;
      // Smooth scroll to expanded content
      if (group.showDetails) {
        this.$nextTick(() => {
          this.$refs[`group-${group.id}`][0].scrollIntoView({ behavior: 'smooth' });
        });
      }
    },

    getTimeDetails(timestamp) {
      const time = moment(timestamp);
      return {
        relative: time.fromNow(),
        absolute: time.format('DD. MMMM YYYY, HH:mm:ss')
      };
    },

    handlePageChange(page) {
      this.$emit('page-change', page - 1); // Emittiert das Event für die übergeordnete Komponente
    },

    mapActivityTypeToFilter(type) {
      const typeMap = {
        'sent': ['sent', 'mail_send', 'rating_send'],
        'opened': ['opened', 'mail_opened', 'rating_opened'],
        'clicked': ['clicked', 'link_clicked'],
        'rating': ['rating_send', 'rating_opened']
      };

      for (const [filterType, types] of Object.entries(typeMap)) {
        if (types.includes(type.toLowerCase())) {
          return filterType;
        }
      }
      return 'other';
    },

    processActivities() {
      console.log('Processing activities:', {
        emailInteractions: this.localEmailInteractions,
        activities: this.localActivities
      });
    },

    groupActivitiesByEmail() {
      const groups = new Map();

      // Stelle sicher, dass wir mit Arrays arbeiten
      const interactions = Array.isArray(this.localEmailInteractions) ? this.localEmailInteractions : [];
      const activities = Array.isArray(this.localActivities) ? this.localActivities : [];

      console.log('Starting grouping with:', {
        emailInteractions: interactions,
        activities: activities
      });

      // Verarbeite Email Interactions
      interactions.forEach(interaction => {
        if (!interaction) return;

        // Verwende Betreff als Gruppierungsschlüssel
        const subject = interaction.campaignSubject || interaction.subject;
        const groupKey = interaction.mailgunId || (subject ? subject.toLowerCase().trim() : null);

        if (!groupKey) return;

        if (!groups.has(groupKey)) {
          groups.set(groupKey, {
            id: groupKey,
            subject: subject,
            activities: [],
            statusSummary: [],
            showDetails: false,
            latestActivity: null,
            mailgunId: interaction.mailgunId
          });
        }

        const group = groups.get(groupKey);
        group.activities.push(interaction);

        if (!group.latestActivity ||
            new Date(interaction.timestamp) > new Date(group.latestActivity.timestamp)) {
          group.latestActivity = interaction;
        }

        this.updateStatusSummary(group, interaction);
      });

      // Verarbeite Activities
      activities.forEach(activity => {
        if (!activity) return;

        let subject = activity.subject;
        const messageMatch = activity.message?.match(/Betreff "([^"]+)"/);
        if (messageMatch) {
          subject = messageMatch[1];
        }

        let group = Array.from(groups.values()).find(g =>
            g.mailgunId === activity.mailgunId ||
            g.subject?.toLowerCase().trim() === subject?.toLowerCase().trim()
        );

        if (!group) {
          const groupKey = activity.mailgunId || subject?.toLowerCase().trim() || 'unknown';
          groups.set(groupKey, {
            id: groupKey,
            subject: subject,
            activities: [],
            statusSummary: [],
            showDetails: false,
            latestActivity: null,
            mailgunId: activity.mailgunId
          });
          group = groups.get(groupKey);
        }

        group.activities.push(activity);

        if (!group.latestActivity ||
            new Date(activity.created) > new Date(group.latestActivity.timestamp)) {
          group.latestActivity = {
            ...activity,
            timestamp: activity.created
          };
        }

        this.updateStatusSummary(group, activity);
      });

      return Array.from(groups.values())
          .sort((a, b) => new Date(b.latestActivity?.timestamp || 0) - new Date(a.latestActivity?.timestamp || 0));
    },

    extractSubjectFromMessage(message) {
      if (!message) return null;

      // Suche nach Betreff in Nachrichten wie "Kunden E-Mail mit Betreff "XYZ" wurde geöffnet."
      const match = message.match(/Betreff "([^"]+)"/);
      return match ? match[1] : null;
    },

    updateLatestActivity(group, activity) {
      const timestamp = activity.timestamp || activity.created;
      if (!group.latestActivity || new Date(timestamp) > new Date(group.latestActivity.timestamp)) {
        group.latestActivity = {
          ...activity,
          timestamp: timestamp
        };
      }
    },

    updateStatusSummary(group, activity) {
      const type = activity.eventType || activity.type;
      const timestamp = activity.timestamp || activity.created;
      const mailgunId = activity.mailgunId;

      // Konvertiere Timestamps zu Unix-Zeitstempel in Sekunden
      const getTimeInSeconds = (time) => Math.floor(new Date(time).getTime() / 1000);
      const activityTimeSeconds = getTimeInSeconds(timestamp);

      // Entferne ähnliche Einträge basierend auf:
      // 1. Gleicher mailgunId
      // 2. Gleichem Typ und ähnlichem Timestamp (±5 Sekunden)
      group.statusSummary = group.statusSummary.filter(s => {
        const sameType = s.type === type;
        const similarTime = Math.abs(getTimeInSeconds(s.timestamp) - activityTimeSeconds) <= 5;
        const sameMail = mailgunId && s.mailgunId === mailgunId;

        // Behalte den Eintrag nur wenn es NICHT der gleiche ist
        return !(sameType && (similarTime || sameMail));
      });

      // Füge neuen Status hinzu
      group.statusSummary.push({
        type,
        label: this.getEventLabel(type),
        timestamp: timestamp,
        details: activity.details || activity.message,
        mailgunId: mailgunId
      });

      // Sortiere nach Timestamp (neueste zuerst)
      group.statusSummary.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));
    },

    getEventLabel(type) {
      const labels = {
        SENT: 'Versendet',
        DELIVERED: 'Zugestellt',
        OPENED: 'Geöffnet',
        FAILED: 'Nicht zugestellt',
        CLICKED: 'Angeklickt',
        MAIL_SEND: 'Versendet',
        MAIL_OPENED: 'Geöffnet',
        LINK_CLICKED: 'Angeklickt',
        RATING_SEND: 'Bewertung versendet',
        RATING_OPENED: 'Bewertung geöffnet',
        OPT_IN_CONFIRMED: 'Double Opt-in bestätigt',
        FUNNEL_SEND: 'Funnel versendet',
        FUNNEL_OPENED: 'Funnel geöffnet'
      };
      return labels[type] || type;
    },

    getStatusColor(type) {
      const colors = {
        SENT: 'primary',
        DELIVERED: 'info',
        OPENED: 'success',
        CLICKED: 'warning',
        MAIL_SEND: 'primary',
        MAIL_OPENED: 'success',
        LINK_CLICKED: 'warning',
        RATING_SEND: 'primary',
        RATING_OPENED: 'success',
        FUNNEL_SEND: 'primary',
        FUNNEL_OPENED: 'success',
        COMPLAINED: 'danger',
        PERMANENT_FAIL: 'danger',
        TEMPORARY_FAIL: 'warning'
      };
      return colors[type] || 'secondary';
    },

    getEventColor(type) {
      return `event-${this.getStatusColor(type)}`;
    },

    clearFilters() {
      this.filters = {
        search: '',
        type: 'all',
        recipient: 'all'
      };
    },

    isAdminNotification(activity) {
      const subject = activity.campaignSubject || activity.subject || '';
      const details = activity.details || activity.message || '';

      return subject.includes('Neuer Lead:') ||
        subject.includes('{{VORNAME}}') ||
        subject.includes('{{GESAMT_ERGEBNIS}}') ||
        details.includes('Lead:') ||
        (subject.toLowerCase().includes('administrator') &&
          details.toLowerCase().includes('administrator'));
    },
    normalizeActivities() {
      // Erstelle eine Map für schnellen Zugriff auf existierende Aktivitäten
      const activityMap = new Map();

      // Normalisiere Email-Interaktionen
      this.emailInteractions.forEach(interaction => {
        const key = `${interaction.timestamp}_${interaction.eventType}`;
        activityMap.set(key, {
          id: interaction.interactionId,
          timestamp: interaction.timestamp,
          type: interaction.eventType,
          title: this.getInteractionTitle(interaction),
          details: interaction.details,
          campaign: interaction.campaignName,
          subject: interaction.campaignSubject
        });
      });

      // Füge Activities hinzu oder aktualisiere existierende
      this.activities.forEach(activity => {
        const key = `${activity.created}_${this.mapActivityType(activity.type)}`;
        const existing = activityMap.get(key);

        if (existing) {
          // Erweitere existierenden Eintrag
          existing.details = this.mergeDetails(existing.details, activity.message);
          existing.mailgunId = activity.mailgunId;
        } else {
          // Füge neuen Eintrag hinzu
          activityMap.set(key, {
            id: activity.id,
            timestamp: activity.created,
            type: activity.type,
            title: activity.subject,
            details: activity.message,
            mailgunId: activity.mailgunId
          });
        }
      });

      // Konvertiere Map zu Array und sortiere nach Timestamp
      this.normalizedActivities = Array.from(activityMap.values())
        .sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));
    },

    mapActivityType(type) {
      const typeMap = {
        'MAIL_OPENED': 'OPENED',
        'MAIL_SEND': 'SENT',
        'LINK_CLICKED': 'CLICKED',
        'RATING_OPENED': 'OPENED',
        'RATING_SEND': 'SENT'
      };
      return typeMap[type] || type;
    },

    getInteractionTitle(interaction) {
      const titleMap = {
        'SENT': 'Versendet',
        'DELIVERED': 'Zugestellt',
        'OPENED': 'Geöffnet',
        'FAILED': 'Nicht zugestellt',
        'CLICKED': 'Angeklickt',
        'MAIL_SEND': 'Versendet',
        'MAIL_OPENED': 'Geöffnet',
        'LINK_CLICKED': 'Angeklickt',
        'RATING_SEND': 'Bewertung versendet',
        'RATING_OPENED': 'Bewertung geöffnet'
      };
      return titleMap[interaction.eventType] || interaction.eventType;
    },

    getActivityDetails(activity) {
      const subject = activity.campaignSubject || activity.subject || '';
      const details = activity.details || activity.message || '';

      // Wenn es eine Admin-Benachrichtigung ist
      if (this.isAdminNotification(activity)) {
        // Wenn es ein Template ist, ignoriere diese Nachricht komplett
        if (details.includes('{{')) {
          return null;
        }

        // Für bereits aufgelöste Lead-Details
        if (details.includes('Lead:')) {
          // Extrahiere die Werte aus dem Format "Lead: 378.250,00 €-Erbengemeinschaft-Ohmstraße 8A, 16341 Panketal, Deutschland-Haus"
          const matches = details.match(/Lead:\s*([\d.,]+)\s*€-([^-]+)-([^-]+)-([^-]+)/);
          if (matches) {
            const [, price, reason, address, type] = matches;
            return `Lead-Zusammenfassung: ${type} in ${address} (${price} €)`;
          }
        }

        // Für Nachrichten mit "wurde geöffnet"
        if (details.includes('wurde geöffnet')) {
          const leadMatches = details.match(/Betreff "Lead: ([\d.,]+)\s*€-([^-]+)-([^-]+)-([^-]+)"/);
          if (leadMatches) {
            const [, price, reason, address, type] = leadMatches;
            return `Lead-Zusammenfassung: ${type} in ${address} (${price} €)`;
          }
        }
      }

      // Für normale E-Mails
      if (subject) {
        if (details && details.includes('Kunden E-Mail')) {
          return details;
        }
        // Entferne "Lead:" Prefix wenn vorhanden
        return `E-Mail "${subject.replace(/^Lead:\s*/, '')}"`;
      }

      return details;
    },

// Neue Hilfsmethode zum Formatieren der Lead-Details
    formatLeadDetails(details) {
      // Extrahiert die Werte aus dem Format "Lead: 378.250,00 €-Erbengemeinschaft-Ohmstraße 8A, 16341 Panketal, Deutschland-Haus"
      const matches = details.match(/Lead:\s*([\d.,]+)\s*€-([^-]+)-([^-]+)-([^-]+)/);
      if (matches) {
        const [, price, reason, address, type] = matches;
        return `${type} in ${address} (${price} €)`;
      }
      return details;
    },

// Neue Hilfsmethode zum Finden der tatsächlichen Nachricht
    findRealMessageForTemplate(activity) {
      // Suche in den emailInteractions nach einer Nachricht mit gleichen Zeitstempel
      // aber mit aufgelösten Variablen
      const timestamp = activity.timestamp || activity.created;
      return this.computedData.emailInteractions?.find(interaction =>
        interaction.timestamp === timestamp &&
        interaction.eventType === activity.eventType &&
        !interaction.details.includes('{{') &&
        interaction.details.includes('Lead:')
      )?.details;
    },

    findGroupForActivity(activity) {
      // Diese Methode wird intern verwendet, um die zugehörige Gruppe zu finden
      const subject = this.extractSubjectFromMessage(activity.message) || activity.subject;
      return this.emailGroups?.find(group =>
        group.mailgunId === activity.mailgunId ||
        group.subject?.toLowerCase().trim() === subject?.toLowerCase().trim()
      );
    },

    cleanupTemplateVars(text) {
      return text.replace(/{{[^}]+}}/g, '...');
    },

    extractLeadInfoFromDetails(details) {
      // Beispiel: "Lead: 542.850,00 €-Ohne Grund-Kienhorststraße 4A, 14476 Potsdam, Deutschland-Haus"
      const matches = details.match(/Lead: ([\d.,]+) €-([^-]+)-([^-]+)-([^-]+)/);
      if (matches) {
        const [, price, reason, address, type] = matches;
        return `${type} - ${address} (${price})`;
      }
      return details;
    },

    getActivityBadgeText(activity) {
      const type = this.getActivityType(activity);
      const typeMap = {
        'SENT': this.isAdminNotification(activity) ? 'An Admin gesendet' : 'Versendet',
        'DELIVERED': this.isAdminNotification(activity) ? 'An Admin zugestellt' : 'Zugestellt',
        'OPENED': this.isAdminNotification(activity) ? 'Von Admin geöffnet' : 'Geöffnet',
        'CLICKED': 'Angeklickt',
        'FAILED': 'Nicht zugestellt',
        'MAIL_SEND': this.isAdminNotification(activity) ? 'An Admin gesendet' : 'Versendet',
        'MAIL_OPENED': this.isAdminNotification(activity) ? 'Von Admin geöffnet' : 'Geöffnet',
        'LINK_CLICKED': 'Angeklickt',
        'RATING_SEND': 'Bewertung versendet',
        'RATING_OPENED': 'Bewertung geöffnet',
        'OPT_IN_CONFIRMED': 'Double Opt-in bestätigt',
        'FUNNEL_SEND': 'Funnel versendet',
        'FUNNEL_OPENED': 'Funnel geöffnet'
      };
      return typeMap[type] || type;
    },

    mergeDetails(detail1, detail2) {
      if (!detail1) return detail2;
      if (!detail2) return detail1;
      if (detail1.includes(detail2)) return detail1;
      if (detail2.includes(detail1)) return detail2;
      return `${detail1} | ${detail2}`;
    },

    formatDate(date) {
      // Spezielle Behandlung für "Heute" und "Gestern"
      if (date === 'Heute' || date === 'Gestern') {
        return date;
      }

      let momentDate;

      // Versuche verschiedene Parsing-Strategien
      if (typeof date === 'string') {
        // Versuche zuerst ISO Format
        momentDate = moment(date, moment.ISO_8601);

        // Wenn das nicht funktioniert, versuche deutsches Format
        if (!momentDate.isValid()) {
          momentDate = moment(date, 'DD. MMMM YYYY', 'de');
        }
      } else {
        momentDate = moment(date);
      }

      // Überprüfe ob das Datum gültig ist
      if (!momentDate.isValid()) {
        console.warn('Could not parse date:', date);
        return 'Ungültiges Datum';
      }

      const today = moment().startOf('day');
      const yesterday = moment().subtract(1, 'days').startOf('day');

      if (momentDate.isSame(today, 'day')) {
        return 'Heute';
      } else if (momentDate.isSame(yesterday, 'day')) {
        return 'Gestern';
      } else {
        // Formatiere im deutschen Format
        return momentDate.locale('de').format('DD. MMMM YYYY');
      }
    },

    formatTime(timestamp) {
      let momentTime;

      if (typeof timestamp === 'string') {
        momentTime = moment(timestamp, moment.ISO_8601);

        if (!momentTime.isValid()) {
          momentTime = moment(timestamp, 'HH:mm');
        }
      } else {
        momentTime = moment(timestamp);
      }

      if (!momentTime.isValid()) {
        console.warn('Invalid timestamp format:', timestamp);
        return '--:-- Uhr';
      }

      return `${momentTime.format('HH:mm')} Uhr`;
    },

    getActivityIcon(activity) {
      const type = this.getActivityType(activity);
      switch(type) {
        case 'SENT':
        case 'MAIL_SEND':
          return 'cil-envelope-closed';
        case 'OPENED':
        case 'MAIL_OPENED':
          return 'cil-envelope-open';
        case 'CLICKED':
        case 'LINK_CLICKED':
          return 'cil-cursor';
        default:
          return 'cilEnvelopeLetter';
      }
    },

    getActivityClass(activity) {
      const type = this.getActivityType(activity);
      switch(type) {
        case 'SENT':
        case 'MAIL_SEND':
          return 'activity-sent';
        case 'OPENED':
        case 'MAIL_OPENED':
          return 'activity-opened';
        case 'CLICKED':
        case 'LINK_CLICKED':
          return 'activity-clicked';
        default:
          return '';
      }
    },

    getActivityBadgeColor(activity) {
      const type = this.getActivityType(activity);
      switch(type) {
        case 'SENT':
        case 'MAIL_SEND':
          return 'primary';
        case 'OPENED':
        case 'MAIL_OPENED':
          return 'success';
        case 'CLICKED':
        case 'LINK_CLICKED':
          return 'warning';
        default:
          return 'secondary';
      }
    },

    getActivityType(activity) {
      return activity.eventType || activity.type || 'UNKNOWN';
    },

    // Hilfreiche Debug-Methode
    logGroupStructure(group) {
      console.log('Group Structure:', {
        id: group.id,
        subject: group.subject,
        activitiesCount: group.activities.length,
        statusSummary: group.statusSummary,
        latestActivity: group.latestActivity ? {
          type: this.getActivityType(group.latestActivity),
          timestamp: group.latestActivity.timestamp
        } : null
      });
    }
  },

  created() {
    // Debug-Logging beim Erstellen der Komponente
    console.log('Component created with:', {
      emailInteractions: this.emailInteractions,
      activities: this.activities
    });
  },

};
</script>

<style scoped>
.stat-icon {
  width: 40px;
  height: 40px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0.5rem;
}

.email-activity-list {
  margin-bottom: 2rem;
}

/* Statistics Cards */
/* Statistics Cards */
.statistics-cards {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 2rem;
}

.stat-card {
  padding: 0.5rem;
}

.stat-icon {
  width: 40px;
  height: 40px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 1rem;
}

.stat-content {
  flex: 1;
}

.stat-value {
  font-size: 1.75rem;
  font-weight: 600;
  margin-bottom: 0.25rem;
  color: var(--primary);
}

.stat-label {
  font-size: 0.875rem;
  white-space: nowrap;
}

.bg-primary-light { background-color: #ebeeff; }
.bg-success-light { background-color: #e8fff3; }
.bg-warning-light { background-color: #fff8e8; }
.bg-info-light { background-color: #e8f4ff; }


/* Filter Container */
.filter-container {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  align-items: flex-start;
}

.filter-item {
  flex: 1;
  min-width: 200px;
}

.filter-item :deep(.form-control) {
  height: 35px;
}

/* Loading State */
.loading-state {
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 1rem;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.05);
}

.loading-text {
  flex: 1;
}

/* Email Groups */
.email-group {
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.05);
  overflow: hidden;
}

.email-group-header {
  transition: background-color 0.2s ease;
  border-left: 4px solid transparent;
}

.email-group-header:hover {
  background-color: #f8f9fa;
}

.email-group-header.activity-sent {
  border-left-color: #321fdb;
}

.email-group-header.activity-opened {
  border-left-color: #2eb85c;
}

.email-group-header.activity-clicked {
  border-left-color: #f9b115;
}

/* Activity Icons */
.activity-icon {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: #e9ecef;
  flex-shrink: 0;
}

.activity-sent .activity-icon {
  background-color: #ebeeff;
  color: #321fdb;
}

.activity-opened .activity-icon {
  background-color: #e8fff3;
  color: #2eb85c;
}

.activity-clicked .activity-icon {
  background-color: #fff8e8;
  color: #f9b115;
}

/* Status Badges */
.status-badges {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  margin-top: 0.5rem;
}

.status-badge {
  display: flex;
  align-items: center;
  padding: 0.5rem 0.75rem;
  font-size: 0.775rem;
}

.status-icon {
  font-size: 0.875rem;
  margin-top: -5px !important;
}

/* Timeline */
.date-separator {
  background: #f8f9fa;
  padding: 0.75rem 1rem;
  margin: 1rem 0;
  border-radius: 4px;
  font-weight: 500;
  color: #495057;
}

.timeline-details {
  position: relative;
  padding: 1rem 0;
}

.timeline-event {
  position: relative;
  padding-left: 2rem;
  margin-left: 1rem;
}

.timeline-dot {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 12px;
  height: 12px;
  border-radius: 50%;
  border: 2px solid #fff;
}

.timeline-event::before {
  content: '';
  position: absolute;
  left: 5px;
  top: 0;
  bottom: 0;
  width: 2px;
  background-color: #e9ecef;
}

/* Event Colors */
.event-primary { background-color: var(--primary); }
.event-success { background-color: var(--success); }
.event-warning { background-color: var(--warning); }
.event-info { background-color: var(--info); }

/* Event Content */
.event-type {
  font-weight: 500;
  color: #495057;
}

.event-time {
  color: #6c757d;
  font-size: 0.875rem;
}

.event-details {
  font-size: 0.875rem;
}

/* Admin Notification Styles */
.admin-notification {
  background-color: #f8f9fa;
  border-left-color: #6c757d !important;
}

.admin-notification .activity-icon {
  background-color: #e9ecef;
  color: #6c757d;
}
@media (max-width: 576px) {
  .statistics-cards {
    grid-template-columns: 1fr;
  }
}

/* Responsive Design */
@media (max-width: 768px) {
  .statistics-cards {
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
  }

  .filter-item {
    flex: 0 0 100%;
  }

  .email-group-header {
    flex-direction: column;
  }

  .status-badges {
    flex-direction: column;
    width: 100%;
  }

  .status-badge {
    width: 100%;
  }

  .timeline-event {
    padding-left: 1.5rem;
  }

  .activity-content {
    width: 100%;
  }
}

/* Animation für Collapse */
.email-details {
  transition: all 0.3s ease-in-out;
}

/* Hover Effekte */
.email-group:hover {
  box-shadow: 0 4px 6px rgba(0,0,0,0.1);
}

.email-controls button {
  opacity: 0.7;
  transition: opacity 0.2s ease;
}

.email-controls button:hover {
  opacity: 1;
}

/* Empty State */
.empty-state {
  text-align: center;
  padding: 3rem 1rem;
}

.empty-state .cil-inbox {
  font-size: 3rem;
  color: #6c757d;
  margin-bottom: 1rem;
}
</style>

<template>
  <div class="lead-map">
    <GmapMap
      v-if="isMapReady"
      ref="mapRef"
      :center="mapCenter"
      :zoom="currentZoom"
      :options="{
        zoomControl: true,
        mapTypeControl: false,
        scaleControl: true,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: true,
        disableDefaultUI: false
      }"
      style="width: 100%; height: 400px"
      @idle="handleMapIdle"
    >
      <GmapMarker
        v-for="(marker, index) in markers"
        :key="index"
        :position="marker.position"
        :clickable="true"
        :icon="getMarkerIcon(marker)"
        @click="handleClick(marker)"
      />
    </GmapMap>

    <!-- Details Panel -->
    <transition name="slide-fade">
      <div v-if="isPanelVisible" class="details-panel">
        <div class="panel-header">
          <h3 class="panel-title">Lead Details</h3>
          <button class="close-btn" @click="closePanel">
            <CIcon name="cil-x" />
          </button>
        </div>

        <div v-if="selectedMarker" class="lead-info">
          <!-- Lead Status Badge -->
          <div class="status-badge" :class="getStatusClass(selectedMarker)">
            {{ getStatusLabel(selectedMarker) }}
          </div>

          <!-- Hauptinformationen -->
          <div class="info-section">
            <h4 class="section-title">Kontaktdaten</h4>
            <div class="info-grid">
              <div class="info-item">
                <CIcon name="cil-user" class="info-icon" />
                <div class="info-content">
                  <label>Name</label>
                  <span>{{ selectedMarker.name }}</span>
                </div>
              </div>
              <div class="info-item">
                <CIcon name="cil-envelope-closed" class="info-icon" />
                <div class="info-content">
                  <label>Email</label>
                  <a :href="'mailto:' + selectedMarker.email">{{ selectedMarker.email }}</a>
                </div>
              </div>
              <div class="info-item">
                <CIcon name="cil-phone" class="info-icon" />
                <div class="info-content">
                  <label>Telefon</label>
                  <a :href="'tel:' + selectedMarker.phone">{{ selectedMarker.phone }}</a>
                </div>
              </div>
            </div>
          </div>

          <!-- Adressinformationen -->
          <div class="info-section">
            <h4 class="section-title">Standort</h4>
            <div class="info-item address">
              <CIcon name="cil-location-pin" class="info-icon" />
              <div class="info-content">
                <label>Adresse</label>
                <span>{{ selectedMarker.address }}</span>
              </div>
            </div>
          </div>

          <!-- Zusätzliche Informationen -->
          <div class="info-section">
            <h4 class="section-title">Details</h4>
            <div class="info-grid">
              <div class="info-item">
                <CIcon name="cil-calendar" class="info-icon" />
                <div class="info-content">
                  <label>Erstellt am</label>
                  <span>{{ formatDate(selectedMarker.createdAt) }}</span>
                </div>
              </div>
              <div class="info-item">
                <CIcon name="cil-link" class="info-icon" />
                <div class="info-content">
                  <label>Quelle</label>
                  <span>{{ selectedMarker.source || 'Nicht angegeben' }}</span>
                </div>
              </div>
            </div>
          </div>

          <!-- Aktionen -->
          <div class="panel-actions">
            <button @click="zoomToMarker(selectedMarker)" class="action-btn primary">
              <CIcon name="cil-zoom-in" />
              Zum Standort zoomen
            </button>
            <button @click="openLeadDetails" class="action-btn secondary">
              <CIcon name="cil-description" />
              Lead Details öffnen
            </button>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { CIcon } from '@coreui/vue'
import moment from 'moment'

export default {
  name: "LeadMap",

  components: {
    CIcon
  },

  props: {
    markers: {
      type: Array,
      required: true
    },
    center: {
      type: Object,
      default: () => ({
        lat: 50.90055852891332,
        lng: 10.463925212353091
      })
    },
    zoom: {
      type: Number,
      default: 6
    }
  },

  data() {
    return {
      isMapReady: false,
      selectedMarker: null,
      isPanelVisible: false,
      mapCenter: this.center,
      currentZoom: this.zoom,
      hasInitiallyFitBounds: false
    }
  },

  methods: {
    handleMapIdle() {
      if (!this.hasInitiallyFitBounds && this.markers.length > 0 && this.$refs.mapRef && this.$refs.mapRef.$mapObject) {
        console.log('Fitting bounds on idle');
        this.$nextTick(() => {
          this.fitBounds();
          this.hasInitiallyFitBounds = true;
        });
      }
      this.$emit('map-ready');
    },

    getMarkerIcon(marker) {
      return {
        path: 'M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z',
        fillColor: this.getMarkerColor(marker),
        fillOpacity: 1,
        strokeWeight: 1,
        strokeColor: '#FFFFFF',
        scale: 1.5,
        anchor: { x: 12, y: 22 }
      };
    },

    getMarkerColor(marker) {
      if (marker.hasDocument) return "#38A169";
      if (marker.isNew) return "#D69E2E";
      return "#3182CE";
    },

    getStatusClass(marker) {
      if (marker.hasDocument) return 'status-success';
      if (marker.isNew) return 'status-warning';
      return 'status-default';
    },

    getStatusLabel(marker) {
      if (marker.hasDocument) return 'Mit Dokument';
      if (marker.isNew) return 'Neu';
      return 'Standard';
    },

    handleClick(marker) {
      console.log('Marker clicked:', marker);
      this.selectedMarker = marker;
      this.isPanelVisible = true;

      this.$nextTick(() => {
        if (this.$refs.mapRef && this.$refs.mapRef.$mapObject) {
          const map = this.$refs.mapRef.$mapObject;
          map.setCenter(marker.position);
          map.setZoom(14);
        }
      });
    },

    fitBounds() {
      if (!this.markers.length || !this.$refs.mapRef || !this.$refs.mapRef.$mapObject) {
        console.log('Keine Marker oder Map nicht bereit');
        return;
      }

      try {
        const bounds = new google.maps.LatLngBounds();

        this.markers.forEach(marker => {
          if (marker.position) {
            bounds.extend(marker.position);
          }
        });

        this.$nextTick(() => {
          if (this.$refs.mapRef && this.$refs.mapRef.$mapObject) {
            this.$refs.mapRef.$mapObject.fitBounds(bounds);

            // Überprüfe und passe den Zoom-Level an
            const currentZoom = this.$refs.mapRef.$mapObject.getZoom();
            if (currentZoom > 14) {
              this.$refs.mapRef.$mapObject.setZoom(14);
            } else if (currentZoom < 5) {
              this.$refs.mapRef.$mapObject.setZoom(5);
            }
          }
        });
      } catch (error) {
        console.warn('Error in fitBounds:', error);
      }
    },

    zoomToMarker(marker) {
      if (!marker || !this.$refs.mapRef) return;

      const map = this.$refs.mapRef.$mapObject;
      if (map) {
        map.setCenter(marker.position);
        map.setZoom(14);
      }
    },

    formatDate(date) {
      return moment(date).format('DD.MM.YYYY HH:mm');
    },

    formatUnknownValue(value, type) {
      if (!value) {
        switch (type) {
          case "name": return "Unbekannter Kontakt";
          case "email": return "Keine E-Mail angegeben";
          case "phone": return "Keine Telefonnummer angegeben";
          case "source": return "Unbekannte Quelle";
          default: return "Nicht verfügbar";
        }
      }
      return value;
    },

    closePanel() {
      this.isPanelVisible = false;
      this.selectedMarker = null;
    },

    openLeadDetails() {
      if (this.selectedMarker && this.selectedMarker.id) {
        // Navigiere zur Lead-Edit-Seite mit der korrekten ID
        this.$router.push(`/leads/edit/${this.selectedMarker.id}`);
      }
    }
  },

  mounted() {
    this.isMapReady = true;
    this.$nextTick(() => {
      if (this.markers.length > 0) {
        setTimeout(() => {
          this.fitBounds();
        }, 1000);
      }
    });
  },

  watch: {
    markers: {
      handler(newMarkers) {
        console.log('Markers updated:', newMarkers.length);
        if (newMarkers.length > 0 && this.isMapReady) {
          this.$nextTick(() => {
            this.hasInitiallyFitBounds = false;
            this.fitBounds();
          });
        }
      },
      deep: true
    }
  }
}
</script>

Hier ist der CSS-Teil der LeadMap.vue:

```vue
<style scoped>
:root {
  --primary-color: #3182ce;
  --primary-hover: #2c5282;
  --success-color: #38a169;
  --warning-color: #d69e2e;
  --danger-color: #e53e3e;
  --background-color: #f7fafc;
  --card-background: #ffffff;
  --text-primary: #1a202c;
  --text-secondary: #4a5568;
  --border-color: #e2e8f0;
  --shadow-sm: 0 1px 2px rgba(0, 0, 0, 0.05);
  --shadow-md: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
  --shadow-lg: 0 10px 15px -3px rgba(0, 0, 0, 0.1);
}

.lead-map {
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 0.5rem;
  overflow: hidden;
}

/* Details Panel */
.details-panel {
  position: absolute;
  top: 0;
  right: 0;
  width: 380px;
  height: 100%;
  background: var(--card-background);
  box-shadow: var(--shadow-lg);
  overflow-y: auto;
  z-index: 1000;
}

.panel-header {
  padding: 1.25rem;
  border-bottom: 1px solid var(--border-color);
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: var(--background-color);
}

.panel-title {
  font-size: 1.25rem;
  font-weight: 600;
  color: var(--text-primary);
  margin: 0;
}

.close-btn {
  padding: 0.5rem;
  background: transparent;
  border: none;
  cursor: pointer;
  color: var(--text-secondary);
  transition: color 0.2s ease;
  border-radius: 0.375rem;
}

.close-btn:hover {
  color: var(--text-primary);
  background: var(--border-color);
}

.lead-info {
  padding: 1.5rem;
}

/* Status Badge */
.status-badge {
  display: inline-flex;
  align-items: center;
  padding: 0.5rem 1rem;
  border-radius: 9999px;
  font-size: 0.875rem;
  font-weight: 500;
  margin-bottom: 1.5rem;
}

.status-success {
  background-color: #C6F6D5;
  color: #22543D;
}

.status-warning {
  background-color: #FEFCBF;
  color: #744210;
}

.status-default {
  background-color: #EDF2F7;
  color: #2D3748;
}

/* Info Sections */
.info-section {
  margin-bottom: 2rem;
  background: var(--background-color);
  border-radius: 0.5rem;
  padding: 1rem;
}

.section-title {
  font-size: 1rem;
  font-weight: 600;
  color: var(--text-primary);
  margin: 0 0 1rem 0;
}

.info-grid {
  display: grid;
  gap: 1rem;
}

.info-item {
  display: flex;
  align-items: flex-start;
  gap: 1rem;
}

.info-icon {
  width: 1.25rem;
  height: 1.25rem;
  color: var(--text-secondary);
  flex-shrink: 0;
  margin-top: 0.25rem;
}

.info-content {
  flex: 1;
}

.info-content label {
  display: block;
  font-size: 0.75rem;
  color: var(--text-secondary);
  margin-bottom: 0.25rem;
}

.info-content span,
.info-content a {
  display: block;
  color: var(--text-primary);
  font-size: 0.875rem;
  line-height: 1.25;
}

.info-content a {
  color: var(--primary-color);
  text-decoration: none;
}

.info-content a:hover {
  text-decoration: underline;
}

/* Panel Actions */
.panel-actions {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  margin-top: 2rem;
}

.action-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  padding: 0.75rem 1rem;
  border-radius: 0.5rem;
  font-size: 0.875rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
  border: none;
}

.action-btn.primary {
  background-color: var(--primary-color);
  color: white;
}

.action-btn.primary:hover {
  background-color: var(--primary-hover);
  transform: translateY(-1px);
}

.action-btn.secondary {
  background-color: var(--background-color);
  color: var(--text-primary);
  border: 1px solid var(--border-color);
}

.action-btn.secondary:hover {
  background-color: var(--border-color);
  transform: translateY(-1px);
}

/* Animations */
.slide-fade-enter-active,
.slide-fade-leave-active {
  transition: transform 0.3s ease, opacity 0.3s ease;
}

.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateX(100%);
  opacity: 0;
}

/* Scrollbar Styling */
.details-panel::-webkit-scrollbar {
  width: 8px;
}

.details-panel::-webkit-scrollbar-track {
  background: var(--background-color);
}

.details-panel::-webkit-scrollbar-thumb {
  background-color: var(--border-color);
  border-radius: 4px;
}

.details-panel::-webkit-scrollbar-thumb:hover {
  background-color: var(--text-secondary);
}

/* Dark Mode */
:global(.dark-mode) {
  --background-color: #1a202c;
  --card-background: #2d3748;
  --text-primary: #f7fafc;
  --text-secondary: #a0aec0;
  --border-color: #4a5568;
}

:global(.dark-mode) .details-panel {
  background: var(--card-background);
}

:global(.dark-mode) .panel-header {
  background: var(--background-color);
}

:global(.dark-mode) .info-section {
  background: rgba(0, 0, 0, 0.2);
}

:global(.dark-mode) .action-btn.secondary {
  background-color: var(--background-color);
  border-color: var(--border-color);
  color: var(--text-primary);
}

:global(.dark-mode) .action-btn.secondary:hover {
  background-color: var(--border-color);
}

/* Responsive Design */
@media (max-width: 768px) {
  .details-panel {
    width: 100%;
    height: 50%;
    top: auto;
    bottom: 0;
  }

  .slide-fade-enter,
  .slide-fade-leave-to {
    transform: translateY(100%);
  }
}

@media (max-width: 480px) {
  .info-item {
    flex-direction: column;
    gap: 0.5rem;
  }

  .info-icon {
    margin-top: 0;
  }

  .panel-actions {
    padding: 0 1rem 1rem;
  }
}

/* Map Controls */
.map-controls {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 1;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.map-control-btn {
  background: white;
  border: none;
  border-radius: 4px;
  padding: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease;
}

.map-control-btn:hover {
  background: #f8fafc;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transform: translateY(-1px);
}
</style>
